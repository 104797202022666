import React from "react"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"

import ContentError from "components/404/ContentError"

import usePage404 from "hooks/usePage404"

const NotFoundPage = () => {
  const content = usePage404()
  return (
    <Layout noFooter noScroll>
      <SEO
        title={content.metaTitle}
        description={content.metaDescription}
        keywords={content.metaKeywords}
      />
      <ContentError {...content} />
    </Layout>
  )
}

export default NotFoundPage

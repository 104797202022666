import React from 'react'
import css from './404.module.scss'

import withBlackHeader from 'HOC/withBlackHeader'

const ContentError = ({ title, content, image, newsButtonText, newsButtonLink, mainButtonText, mainButtonLink }) => {
  return (
    <div className={css.background} key={'404-background'}>
      <div className={css.content}>
        <img src={image} alt={title} className={css.image} />

        <div className={css.descr}>
          <p className={css.smallText}>{title}</p>
          <h2 className={css.bigText}>{content}</h2>

          <button className={css.button}>
            <a href={newsButtonLink}><h3 className={css.link}>{newsButtonText} &#8594;</h3></a>
          </button>
          <button className={css.button}>
            <a href={mainButtonLink}><h3 className={css.link}>{mainButtonText} &#8594;</h3></a>
          </button>
        </div>
      </div>
    </div>
)}

export default withBlackHeader(ContentError)
